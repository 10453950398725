import flatpickr from 'flatpickr';
import $ from 'jquery';

export class Datepicker {
  constructor(datepicker) {
    this.datepicker = datepicker;
    this.flatpickr = flatpickr(this.datepicker, {
      allowInput: true,
      altInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d',
      onReady: (selectedDate, dateStr, instance) => {
        const input = instance.element;
        const inputParent = input.parentElement;
        const visibleDateInput = inputParent.querySelector('input[type="text"]');
        visibleDateInput.setAttribute('name', input.name);
        if (input.attributes.autocomplete) {
          visibleDateInput.setAttribute('autocomplete', input.attributes.autocomplete.value);
        }
        visibleDateInput.after(input);
        inputParent.classList.add('has-datepicker');
      },
      onChange: (selectedDate, dateStr, instance) => {
        const input = instance.element;
        const inputParent = input.parentElement;
        const visibleDateInput = inputParent.querySelector('input[type="text"]');
        if (dateStr) {
          $(visibleDateInput).disableClientSideValidations();
          visibleDateInput.classList.remove('is-invalid');
        } else {
          $(visibleDateInput).enableClientSideValidations();
        }
      },
    });

    const { datepickerMinDate } = this.datepicker.dataset;
    if (datepickerMinDate) {
      this.flatpickr.set('minDate', datepickerMinDate);
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.flatpickr.input.addEventListener('change', (e) => {
      const selectedDate = e.target.value;
      const { datepickerFor, datepickerForStartDate, datepickerForEndDate } = e.target.dataset;

      if (datepickerForStartDate !== 'undefined') {
        const correspondingEndDateField = document.querySelector(
          `[data-datepicker-for="${datepickerFor}"][data-datepicker-for-end-date]`
        );

        if (correspondingEndDateField) {
          const endDateFlatpickrInstance = correspondingEndDateField._flatpickr;

          if (endDateFlatpickrInstance) {
            endDateFlatpickrInstance.set('minDate', selectedDate);
          }
        }
      }

      if (datepickerForEndDate !== 'undefined') {
        const correspondingStartDateField = document.querySelector(
          `[data-datepicker-for="${datepickerFor}"][data-datepicker-for-start-date]`
        );

        if (correspondingStartDateField && !correspondingStartDateField.value) {
          correspondingStartDateField.value = selectedDate;
        }
      }
    });
  }
}
