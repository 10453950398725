import { Controller } from '@hotwired/stimulus';
import Dropzone from 'dropzone';
import heic2any from 'heic2any';
import Sortable from 'sortablejs';

import { DirectUploadController } from './direct-upload';
import { getMetaValue, removeElement } from '../../shared/util';

export default class extends Controller {
  static targets = ['inputsWrapper', 'input', 'uploadArea', 'uploadsCountIndicator', 'uploadsCount'];

  connect() {
    this.config = {
      previewsContainerSelector: '.dropzone-previews',
    };

    // need to save ref as this input gets removed from the DOM when list of inputs gets re-built during re-ordering
    this.inputTargetRef = this.element.querySelector('[data-dropzone-target="input"]');

    this.dropzone = this._createDropzone();
    this.sortable = this._initSortable();

    this._hideFileInput();
    this._bindEvents();

    this.displayExistingFiles();
  }

  _createDropzone() {
    const dropzoneConfig = {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: false,
      autoQueue: false,
      previewsContainer: this.config.previewsContainerSelector,
      previewTemplate: document.querySelector('[data-dz-preview-template]').innerHTML,
      // https://github.com/dropzone/dropzone/blob/main/src/options.js#L572
      // https://github.com/dropzone/dropzone/blob/main/src/preview-template.html
      thumbnail: async (file, dataUrl) => {
        if (file.previewElement) {
          const previewThumbnailElementsArr = [
            ...file.previewElement.querySelectorAll('[data-dz-thumbnail]'),
          ];

          // assume there is no need to generate custom thumbnail and just use default
          let finalDataUrl = dataUrl;

          // if HEIC file, browsers can't handle this image format, so we need to convert to JPEG and display new, custom thumbnail
          // there is conversion already happening in the backend but it only heppens at the point of submitting a form and not during image upload stage hence we need a temporary generated file too
          if (file.type === 'image/heic') {
            // (it takes quite a bit of time, so we want to let user know that something is happening behind the scenes when thumbnail is not displaying straight away

            // add generating-thumbnail class
            previewThumbnailElementsArr.forEach((thumbnailElement) => {
              thumbnailElement.closest('.dz-preview').classList.add('dz-generating-thumbnail');
            });

            const convertedFile = await this.heicToJpeg(file);
            const convertedBlob = await fetch(URL.createObjectURL(convertedFile));
            finalDataUrl = convertedBlob.url;

            // remove generating-thumbnail class
            previewThumbnailElementsArr.forEach((thumbnailElement) => {
              thumbnailElement.closest('.dz-preview').classList.remove('dz-generating-thumbnail');

              // add extra bits of styling needed for custom, generated thumbnails
              thumbnailElement.style.objectFit = 'cover';
              thumbnailElement.style.width = '100%';
              thumbnailElement.style.height = '100%';
            });
          }

          // default bit of logic extracter from DZ source code with only 1 change which is potentially using data url of converted file (if there was a need to convert)
          file.previewElement.classList.remove('dz-file-preview');
          previewThumbnailElementsArr.forEach((thumbnailElement) => {
            thumbnailElement.alt = file.name;
            thumbnailElement.src = finalDataUrl;
          });

          return setTimeout(() => file.previewElement.classList.add('dz-image-preview'), 1);
        }
      },
    };

    return new Dropzone(this.uploadAreaTarget, dropzoneConfig);
  }

  _initSortable() {
    const previewsContainer = this.element.querySelector(this.config.previewsContainerSelector);
    return new Sortable(previewsContainer, {
      onEnd: (e) => {
        // on drag end loop through thumbnails and gather signedIds (in new order)
        const thumbnails = previewsContainer.querySelectorAll('.dz-image-preview');
        const newlyOrderedSignedIds = [...thumbnails].map((thumbnail) => thumbnail.dataset.signedId);

        // regenerate list of inputs (including hidden ones) based on new order
        this.inputsWrapperTarget.innerHTML = '';
        this.inputsWrapperTarget.appendChild(this.inputTargetRef);
        // .reverse() necessary to keep the correct order
        newlyOrderedSignedIds.reverse().forEach((signedId) => {
          const hiddenInput = document.createElement('input');
          hiddenInput.type = 'hidden';
          hiddenInput.name = this.inputTargetRef.name;
          hiddenInput.value = signedId;

          this.inputsWrapperTarget.appendChild(hiddenInput);
        });
      },
    });
  }

  _hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  _bindEvents() {
    this.dropzone.on('addedfile', (originalImage) => {
      setTimeout(async () => {
        if (originalImage.accepted) {
          const directUploadController = new DirectUploadController(this, originalImage);
          directUploadController.start();
        }
      }, 500);
    });

    this.dropzone.on('success', (successfulFile) => {
      // add signedId as thumbnail's data attribute so it can then later on be used to generate new order of files
      const thumbnail = successfulFile.previewElement;
      thumbnail.setAttribute('data-signed-id', successfulFile.signedId);

      this.updateCounter();
    });

    this.dropzone.on('addedfile', (file) => {
      this.dropzone.element.classList.add('dz-started');
    });

    this.dropzone.on('removedfile', (file) => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
        this.updateCounter();
      }
    });

    this.dropzone.on('canceled', (file) => {
      if (file.controller) {
        file.controller.xhr.abort();
      }
    });

    const previewsContainer = document.querySelector(this.config.previewsContainerSelector);
    previewsContainer.addEventListener('click', (e) => {
      if (e.target === previewsContainer) {
        // only trigger upload input if previews container was clicked on directly and not a thumbnail for example
        this.inputTarget.disabled = false;
        this.inputTarget.click();
      }
    });
  }

  async displayExistingFiles() {
    const existingFilesBlobsMap = await this.fetchExistingFiles();

    // Display responses in the same order as backend using the Map generated above
    // and choosing items by filename (key)
    this.existingImages.forEach(({ filename, signed_id, attachment_id }) => {
      const existingBlobData = existingFilesBlobsMap.get(filename);
      const tempFile = new File([existingBlobData], filename, existingBlobData);
      tempFile.signed_id = signed_id;
      tempFile.attachment_id = attachment_id;
      this.dropzone.addFile(tempFile);
    });
  }

  async fetchExistingFiles() {
    // Generates a map where "key" is filename and "value" is a generated blob.
    // Done this way, as fetch/Promise.all() don't keep the responses in the same order as they were requested
    // This way, I can later on loop through existing images array passed from backend that is in the correct order, find a blob by filename (the key)
    // and display existing imagery in the correct order that matches the backend
    const blobsMap = new Map();
    const promises = this.existingImages.map(async ({ filename, url }) => {
      await fetch(url)
        .then((response) => response.blob())
        .then((existingImageBlob) => blobsMap.set(filename, existingImageBlob));
    });

    await Promise.all(promises);

    return blobsMap;
  }

  updateCounter() {
    if (!this.hasUploadsCountTarget || !this.hasUploadsCountIndicatorTarget) {
      return;
    }

    const uploadsCount = this.dropzone.files.length;
    this.uploadsCountTarget.innerHTML = uploadsCount;
    this.uploadsCountIndicatorTarget.classList.toggle('text-success', uploadsCount <= this.maxFiles);
    this.uploadsCountIndicatorTarget.classList.toggle('text-danger', uploadsCount > this.maxFiles);
  }

  heicToJpeg(file) {
    return new Promise((resolve, reject) => {
      heic2any({ blob: file, toType: 'image/jpeg' }).then((fileBlob) => {
        const jpegFile = new File([fileBlob], `${file.name}.jpg`, { type: 'image/jpeg' });
        resolve(jpegFile);
      });
    });
  }

  get url() {
    return this.inputTarget.dataset.directUploadUrl;
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get maxFiles() {
    return parseInt(this.element.dataset.maxFiles, 10) || 10;
  }

  get maxFileSize() {
    return parseInt(this.element.dataset.maxFileSize, 10) || 256;
  }

  get existingImages() {
    try {
      return JSON.parse(this.element.dataset.existingImages);
    } catch (error) {
      return [];
    }
  }

  get acceptedFiles() {
    return this.element.dataset.acceptedFiles || null;
  }

  get addRemoveLinks() {
    return this.element.dataset.addRemoveLinks || true;
  }
}
