import $ from 'jquery';
// import DirtyForm from 'dirty-form';
import Cleave from 'cleave.js';
import { parse as parsePostcode } from 'postcode';
import { FormValidator, COMMONLY_MISSPELLED_EMAIL_DOMAINS } from './form-validator';
import { disableButton, enableButton, isInViewport } from './util';
import { apiClient } from './apiClient';

require('cleave.js/dist/addons/cleave-phone.gb');

// window.jQuery = $;

export class Form {
  constructor(form) {
    this.form = form;

    if (this.form.classList.contains('js-dirty-form')) {
      // const dirtyForm = new DirtyForm(this.form);
    }

    if (this.form.hasAttribute('data-client-side-validations')) {
      this.initClientSideValidation();
      const submitBtn = this.form.querySelector('[data-form-submit-btn]');
      if (submitBtn) {
        submitBtn.addEventListener('click', async (e) => {
          e.preventDefault();
          disableButton(submitBtn);

          const formValidationResult = await this.validateForm();
          if (!formValidationResult.isValid) {
            const currentStep = submitBtn.closest(`[data-step]`) || this.form;
            // scroll to the first error (forms can be quite tall and the error might be somewhere at the top)
            // that can confuse users not being able to proceed because error can be out of their viewport and they can't see it
            const firstError = currentStep.querySelector('.invalid-feedback:not(.d-none)');
            if (firstError && !isInViewport(firstError)) {
              firstError.scrollIntoView({ block: 'center' });
            }

            const extraErrorsContainer = this.form.querySelector('[data-extra-form-errors]');
            if (extraErrorsContainer) {
              if (formValidationResult.errorMessage) {
                extraErrorsContainer.innerText = formValidationResult.errorMessage;
                extraErrorsContainer.classList.remove('d-none');
              } else {
                extraErrorsContainer.innerText = '';
                extraErrorsContainer.classList.add('d-none');
              }
            }

            enableButton(submitBtn);
            return false;
          }

          this.form.submit();
          return true;
        });
      }
    }

    if (this.form.classList.contains('js-needs-bs-validation')) {
      FormValidator.enableBootstrapValidation(this.form);
    }

    const sortCodeFields = this.form.querySelectorAll('.js-sort-code-input');
    if (sortCodeFields.length) {
      sortCodeFields.forEach((sortCodeField) => {
        const cleaveSortCodeField = new Cleave(sortCodeField, {
          numericOnly: true,
          blocks: [2, 2, 2],
          delimiter: '-',
          swapHiddenInput: true,
        });
      });
    }

    const phoneNumberFields = this.form.querySelectorAll('.js-phone-number-input');
    if (phoneNumberFields.length) {
      phoneNumberFields.forEach((phoneNumberField) => {
        const cleavePhoneNumberField = new Cleave(phoneNumberField, {
          phone: true,
          phoneRegionCode: 'GB',
        });
      });
    }

    const timeFields = this.form.querySelectorAll('.js-time-input');
    if (timeFields.length) {
      timeFields.forEach((timeField) => {
        const cleaveTimeField = new Cleave(timeField, {
          time: true,
          timePattern: ['h', 'm'],
        });
      });
    }

    const mileageFields = this.form.querySelectorAll('.js-mileage-input');
    if (mileageFields.length) {
      mileageFields.forEach((mileageField) => {
        mileageField.addEventListener('keydown', (e) => {
          if (e.key === '.') {
            e.preventDefault();
            return false;
          }
          // Makes sure that mileage field is never negative
          const mileage = e.target.value;
          const newValue = !!mileage && Math.abs(mileage) >= 0 ? Math.abs(mileage) : null;
          e.target.value = newValue;
        });
      });
    }

    const regPlateFields = this.form.querySelectorAll('.js-reg-plate-input');
    if (regPlateFields.length) {
      regPlateFields.forEach((regPlateField) => {
        regPlateField.addEventListener('input', (e) => {
          // Prevent non-alphanumeric characters for reg plate
          const regPlate = e.target.value ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, '') : '';
          e.target.value = regPlate;
        });
        regPlateField.addEventListener('blur', (e) => {
          // Remove white spaces from reg plate (to make sure reg plate matches regex for 2 to 7 characters)
          const regPlateWithoutWhiteSpace = e.target.value.replace(/\s+/g, '');
          e.target.value = regPlateWithoutWhiteSpace;
        });
      });
    }

    const postcodeFields = this.form.querySelectorAll('.js-postcode-input');
    if (postcodeFields.length) {
      postcodeFields.forEach((field) => {
        field.addEventListener('change', ({ target }) => {
          const { postcode, valid } = parsePostcode(target.value);
          if (postcode && valid) {
            target.value = postcode;
          }
        });
      });
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    $('[data-form-check-panels-wrapper] input').on('change', function () {
      $(this).closest('[data-form-check-panels-wrapper]').removeClass('is-invalid');
    });
  }

  initClientSideValidation() {
    setTimeout(() => {
      $(this.form).disableClientSideValidations();
    }, 200);
    setTimeout(() => {
      this.validators = this.form.ClientSideValidations.settings.validators;
    }, 500);
  }

  async validateForm() {
    // check if email address domain isn't misspelled
    const emailField = this.form.querySelector('input[type="email"]');
    const domain = emailField && emailField.value.split('@')[1];
    let isEmailDomainValid = true;
    if (domain && (domain.includes('..') || COMMONLY_MISSPELLED_EMAIL_DOMAINS.includes(domain))) {
      isEmailDomainValid = false;

      const existingInvalidFeedback = emailField.parentElement.querySelector('.invalid-feedback');
      if (existingInvalidFeedback) {
        existingInvalidFeedback.remove();
      }
      const invalidFeedbackEl = document.createElement('div');
      invalidFeedbackEl.classList.add('invalid-feedback');
      invalidFeedbackEl.innerText = 'Email contains a misspelled domain';
      emailField.parentElement.append(invalidFeedbackEl);
      emailField.classList.toggle('is-invalid', !isEmailDomainValid);

      return { isValid: false };
    }

    const isPassingStandardValidation = FormValidator.validate(this.form, this.validators);
    if (!isPassingStandardValidation) {
      return { isValid: false };
    }

    return { isValid: true };
  }
}
