import consumer from './consumer';

consumer.subscriptions.create('NotificationsChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.description === 'invoice status updated') {
      const { status, slug } = data.object;

      const customEvent = new CustomEvent('ws:invoice:statusUpdated', {
        detail: {
          status,
          slug,
        },
      });

      document.dispatchEvent(customEvent);
    }
  },
});
