import browserUpdate from 'browser-update';

// Browser check settings
const $buoop = {
  required: {
    e: -4,
    f: -3,
    o: -3,
    s: -1,
    c: -3,
  },
  api: 2021.04,
  insecure: true,
  style: 'bottom',
  unsupported: true,
};

browserUpdate($buoop);
