import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  // init scrolling to element
  if (document.querySelectorAll('.js-scrollto').length) {
    [...document.querySelectorAll('.js-scrollto')].forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const offset = $($(e.currentTarget).attr('href')).offset();
        const scrollTop = $(document).scrollTop();
        const distanceToScroll = offset.top - scrollTop;
        const scrollDuration = distanceToScroll / 60;

        $('html, body').animate(
          {
            scrollTop: offset.top - 140,
          },
          scrollDuration
        );
      });
    });
  }
});
