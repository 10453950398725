import { Controller } from '@hotwired/stimulus';
import { convertToCurrency } from '../../shared/util';

export default class extends Controller {
  static targets = ['marketAveragePrice', 'adjustedPrice'];

  connect() {
    this.valuationBase = this.element.dataset.valuationBase;
    this.valuationAdjustmentPercentage = parseFloat(
      this.element.dataset.valuationAdjustmentPercentage || 0
    );
    this.valuationMultiplier = this.valuationAdjustmentPercentage / 100;

    document.addEventListener('vehicle-lookup:resultRetrieved', (e) => {
      const { valuation } = e.detail;

      if (this.hasAdjustedPriceTarget) {
        let adjustedPrice = null;
        if (valuation) {
          const basePrice = valuation[this.valuationBase];
          adjustedPrice = basePrice + basePrice * this.valuationMultiplier;
        }
        this.adjustedPriceTarget.innerText = adjustedPrice ? convertToCurrency(adjustedPrice) : '–';
      }

      this.marketAveragePriceTargets.forEach((marketAveragePriceTarget) => {
        const { valuationResult } = marketAveragePriceTarget.dataset;
        const hasValuation = valuation && valuation[valuationResult];

        if (hasValuation) {
          const valuationText = convertToCurrency(valuation[valuationResult], true, false);
          marketAveragePriceTarget.innerText = valuationText;
        } else {
          marketAveragePriceTarget.innerText = '–';
          const valuationTile = marketAveragePriceTarget.parentNode.querySelector('.tile');
          if (valuationTile) {
            valuationTile.classList.add('tile-muted');
          }
        }
      });
    });
  }
}
