import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['input', 'indicator', 'characterCount'];

  static debounces = ['updateIndicator'];

  connect() {
    useDebounce(this, { wait: 40 });
    this.maxCharacters = this.element.dataset.maxCharacters;

    this.updateUI(this.inputTarget.value.length);

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.inputTarget.addEventListener('input', (e) => {
      const { length } = e.target.value;
      this.updateUI(length);
    });
  }

  updateUI(characterCount) {
    this.characterCountTarget.innerText = Intl.NumberFormat().format(characterCount);
    this.updateIndicator(characterCount);
  }

  updateIndicator(characterCount) {
    this.indicatorTarget.classList.toggle('text-success', characterCount <= this.maxCharacters);
    this.indicatorTarget.classList.toggle('text-danger', characterCount > this.maxCharacters);
  }
}
