import { loadScript, loadStylesheet } from './util';

setTimeout(() => {
  loadScript('https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js').then(() => {
    // initialize cookie
    const isTCBS = window.location.href.indexOf('thecarbuyingshop') > -1;
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: isTCBS ? '#000' : '#141f2b',
          text: '#fff',
          link: '#f3f7fb',
        },
        button: {
          background: isTCBS ? '#e61996' : '#005fff',
          text: '#fff',
        },
      },
      position: 'bottom-left',
      static: false,
      content: {
        href: isTCBS ? '/cookie-policy' : '/cookies',
      },
    });
  });
  loadStylesheet('https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css');
}, 5000);
