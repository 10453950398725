import Tooltip from 'bootstrap/js/dist/tooltip';

export const initAllTooltips = () => {
  const tooltips = [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map((tooltip) => {
    return new Tooltip(tooltip, {
      delay: { show: 150, hide: 50 },
    });
  });

  return tooltips;
};

export const convertReg = (vrm) => {
  const array = vrm.replace(/\s+/g, '').split('');
  let plate = '';
  array.forEach((value, index) => {
    const integerVal = parseInt(value, 10);
    if (!Number.isNaN(integerVal)) {
      array[index] = integerVal;
    }
  });

  /* eslint default-case: "off" */
  switch (array.length) {
    // eslint ignore /
    case 7:
      // Plate: XXX ###X e.g. DAG 135S and LOI 5755
      if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'string' &&
        typeof array[2] === 'string' &&
        typeof array[3] === 'number'
      ) {
        plate = `${
          array[0] + array[1] + array[2]
        } ${array[3].toString()}${array[4].toString()}${array[5].toString()}${array[6].toString()}`;
        // Plates: XX## XXX e.g. BA11 UXX and X### XXX e.g. P111 DSM
      } else {
        plate = `${array[0] + array[1].toString() + array[2].toString() + array[3].toString()} ${
          array[4]
        }${array[5]}${array[6]}`;
      }
      break;
    case 6:
      // Plate: #### XX e.g. 1990 JD
      if (
        typeof array[0] === 'number' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'number' &&
        typeof array[3] === 'number'
      ) {
        plate = `${
          array[0].toString() + array[1].toString() + array[2].toString() + array[3].toString()
        } ${array[4]}${array[5]}`;
        // Plates: XXX ### e.g. EFZ 600, ### XXX e.g. 280 SLK, XXX ##X e.g. MOW 14M and X## XXX e.g. S15 MJP
      } else {
        plate = `${
          array[0].toString() + array[1].toString() + array[2].toString()
        } ${array[3].toString()}${array[4].toString()}${array[5].toString()}`;
      }
      break;
    case 5:
      // Plate: #### X e.g. 8000 A
      if (typeof array[0] === 'number' && typeof array[1] === 'number') {
        plate = `${
          array[0].toString() + array[1].toString() + array[2].toString() + array[3].toString()
        } ${array[4]}`;
        // Plate: X# XXX e.g. E7 NAS
      } else if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'string'
      ) {
        plate = `${array[0] + array[1].toString()} ${array[2]}${array[3]}${array[4]}`;
        // Plate: X #### e.g. A 2726
      } else if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'number'
      ) {
        plate = `${
          array[0]
        } ${array[1].toString()}${array[2].toString()}${array[3].toString()}${array[4].toString()}`;
        // Plates: XXX ## e.g. REF 60 and XXX #X e.g. RAF 4L
      } else {
        plate = `${array[0] + array[1] + array[2]} ${array[3].toString()}${array[4].toString()}`;
      }
      break;
    case 4:
      // Plate: ### X e.g. 912 E
      if (
        typeof array[0] === 'number' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'number' &&
        typeof array[3] === 'string'
      ) {
        plate = `${array[0].toString() + array[1].toString() + array[2].toString()} ${array[3]}`;
        // Plate: XXX # e.g. PNB 1
      } else if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'string' &&
        typeof array[2] === 'string' &&
        typeof array[3] === 'number'
      ) {
        plate = `${array[0] + array[1] + array[2]} ${array[3].toString()}`;
        // Plate: # XXX e.g. 1 HDD
      } else if (
        typeof array[0] === 'number' &&
        typeof array[1] === 'string' &&
        typeof array[2] === 'string' &&
        typeof array[3] === 'string'
      ) {
        plate = `${array[0].toString()} ${array[1]}${array[2]}${array[3]}`;
        // Plate: X ### e.g. B 166
      } else if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'number' &&
        typeof array[3] === 'number'
      ) {
        plate = `${array[0]} ${array[1].toString()}${array[2].toString()}${array[3].toString()}`;
        // Plate: ## XX e.g. 97 MK
      } else if (
        typeof array[0] === 'number' &&
        typeof array[1] === 'number' &&
        typeof array[2] === 'string' &&
        typeof array[3] === 'string'
      ) {
        plate = `${array[0].toString() + array[1].toString()} ${array[2]}${array[3]}`;
        // Plate: XX ## e.g. AH 72
      } else if (
        typeof array[0] === 'string' &&
        typeof array[1] === 'string' &&
        typeof array[2] === 'number' &&
        typeof array[3] === 'number'
      ) {
        plate = `${array[0] + array[1]} ${array[2].toString()}${array[3].toString()}`;
      }
      break;
    case 3:
      // Plate: ## X e.g. 47 A
      if (typeof array[0] === 'number' && typeof array[1] === 'number') {
        plate = `${array[0].toString() + array[1].toString()} ${array[2]}`;
        // Plate: XX # e.g. VS 5
      } else if (typeof array[0] === 'string' && typeof array[1] === 'string') {
        plate = `${array[0] + array[1]} ${array[2].toString()}`;
        // Plate: # XX e.g. 7 ND
      } else if (typeof array[0] === 'number' && typeof array[1] === 'string') {
        plate = `${array[0].toString()} ${array[1]}${array[2]}`;
        // Plate: X ## e.g. B 16
      } else if (typeof array[0] === 'string' && typeof array[1] === 'number') {
        plate = `${array[0]} ${array[1].toString()}${array[2].toString()}`;
      }
      break;
    case 2:
      // Plates: # X e.g. 8 A and X # e.g. L 1
      plate = `${array[0].toString()} ${array[1].toString()}`;
  }

  return plate;
};

export const convertToCurrency = (amount, alwaysPositive = false, forceDecimals = true) => {
  const isFloat = Number(amount) === amount && amount % 1 !== 0;
  const finalValue = alwaysPositive ? Math.abs(amount) : amount;
  const shouldShowDecimalDigits = forceDecimals || isFloat;

  return finalValue.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: shouldShowDecimalDigits ? 2 : 0,
    maximumFractionDigits: shouldShowDecimalDigits ? 2 : 0,
  });
};

export const toFixedFloat = (val) => {
  return parseFloat(parseFloat(val).toFixed(2));
};

export const getMetaValue = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
};

export const removeElement = (el) => {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export const insertAfter = (el, referenceNode) => {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

export const disableButton = (btn) => {
  btn.disabled = true;
  const { disableWith } = btn.dataset;
  if (disableWith) {
    btn.dataset.originalContent = btn.innerHTML;
    btn.innerHTML = disableWith;
  }
};

export const enableButton = (btn, overrideContent) => {
  btn.disabled = false;
  if (overrideContent) {
    btn.innerHTML = overrideContent;
    return;
  }

  const { originalContent } = btn.dataset;
  if (originalContent) {
    btn.innerHTML = originalContent;
  }
};

export const isEditPage = window.location.href.includes('/edit');

export const isDebugMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('debug') && urlParams.get('debug') === 'true';
};

export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = (err) => {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
};

export const loadStylesheet = (href) => {
  return new Promise((resolve, reject) => {
    const s = document.createElement('link');
    let r = false;
    s.type = 'text/css';
    s.rel = 'stylesheet';
    s.href = href;
    s.onerror = (err) => {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const toSnakeCase = (str) => {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}` : letter;
    })
    .join('');
};

export const sortObjectByKey = (objectToSort) => {
  return Object.keys(objectToSort)
    .sort()
    .reduce((obj, key) => {
      obj[key] = objectToSort[key];
      return obj;
    }, {});
};

export const extractYoutubeVideoIdFromUrl = (url) => {
  let videoId = url.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }
  return videoId;
};
