export const apiClient = (endpoint, { body, ...customConfig } = {}) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const headers = { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(endpoint, config).then(async (response) => {
    if (response.ok) {
      return await response.json();
    }
    const errorObj = await response.json();
    return Promise.reject(errorObj);
  });
};
